import React from 'react';
import './Barchart.css';
import BarCharts from '../../barcharts.json';
import Chart from '../../components/Charts/Chart';

export default function CompareCharts() {
  return (
    <div className='BarChart'>
      <h3>Chart title</h3>
      <div className='all_charts'>
        <div className="chart_items">
          <Chart data={BarCharts.barCharts} />
        </div>
      </div>
    </div>
  )
}
