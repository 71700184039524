import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { subYears, parseISO } from 'date-fns';
import format from 'date-fns/format';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "./Adduserform.css";
import "./Edituserform.css";


export default function AddAttachments({ userId }) {
    // state
    const [salary, setSalary] = useState('');
    const [start_date, setStartDate] = useState('');
    const [comment, setComment] = useState('');
    
    const [userSalary, setUserSalary] = useState([]);
    const [msgErr, setMsgErr] = useState('');
    const [msgValidate, setMsgValidate] = useState('');

    // comportement
    const bearerToken = localStorage.getItem('bearer-token');

    // ** API get User by id
    async function getUserSalary() {
        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}/salaries`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });


            if (response.ok) {
                const userSalaryData = await response.json();
                // console.log(userSalaryData);
                setUserSalary(userSalaryData);

            } else {
                console.log('Error retrieving :', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }


    // ** API update user's salary
    const handleSubmitSalary = async (e) => {
        e.preventDefault();

        const userSalary = {
            salary: salary,
            start_date: start_date,
            comment: comment
        }

        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}/salaries`, {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                },
                body: JSON.stringify(userSalary)
            });


            if (response.ok) {
                setSalary('');
                setStartDate('');
                setComment('');
                console.log('succes');
                setMsgValidate('Salary information updated successfully!');
            } else {
                setMsgErr('Error updating salary!');
                console.log('Error creating user:', response.status);
            }

        } catch (error) {
            console.log('Error:', error);
        }
    }
    

    // affichage
    return (
        <>
        <p>Not Availble</p>
            {/* <form onSubmit={handleSubmitSalary}>
                <div className='add_user_form edit-salary'>
                    <div>
                        <label htmlFor="salary">Salary</label>
                        <input
                            value={salary}
                            onChange={(e) => setSalary(e.target.value)}
                            type="text"
                            name="salary"
                            id="salary"
                            placeholder='Salary'
                            required="required"
                        />
                    </div>
                    
                </div>
                <button type='submit'>Add salary</button>
                {msgErr != "" &&
                    <p className='errorMsg center mr-t-10'>{msgErr}</p>
                }
                {msgValidate != "" &&
                    <p className='validateMsg center mr-t-10'>{msgValidate}</p>
                }
            </form>
            <div className="all_history">
                <table>
                    <thead>
                        <tr>
                            <th>Salary</th>
                            <th>Start Date</th>
                            <th>Comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userSalary.length > 0 &&
                            userSalary
                                .slice() // Create a copy of the array to avoid mutating the original
                                .sort((a, b) => a.start_date.localeCompare(b.start_date)) // Sort by start_date
                                .map(data => (
                                    <tr key={data.id}>
                                        <td>
                                            <p>{data.salary}</p>
                                        </td>
                                        <td>
                                            <p>{data.start_date}</p>
                                        </td>
                                        <td>
                                            <p>{data.comment}</p>
                                        </td>
                                    </tr>
                                ))
                        }
                    </tbody>
                </table>

            </div> */}
        </>
    )
}
