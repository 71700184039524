import React, { useEffect, useState } from 'react';
import axios from 'axios';


export default function Types({ data, api }) {
    // state
    const [showNavPopup, setShowNavPopup] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [ediType, setEditType] = useState(false);
    const [deleteType, setDeletType] = useState(false);
    const [name, setName] = useState(data.name);

    // comportement
    const bearerToken = localStorage.getItem('bearer-token');
    const handleShowNav = () => {
        setShowNavPopup(!showNavPopup);
    }
    const handleEdiPopup = () => {
        setShowEditPopup(!showEditPopup);
    }
    // ** API DELETE attachmenets
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/${api}/${data.id}`, {

                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                // Reset the form fields
                console.log('success !!!');

            } else {
                console.log('Error deleting attachment:', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
        setDeletType(true);
    };
    // ** API Edit attachmenets
    const handleEdit = async (e) => {
        e.preventDefault();

        try {


            const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/${api}/${data.id}`,

                {
                    name: name,
                }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                },
            });

            if (response.status >= 200 && response.status < 300) {
                // Accepted
                setEditType(true);
                // setMsgAccept('Vacation request accepted. The employee has been notified.');
            } else {
                // setMsgErr("Sorry, we couldn't process the vacation request at this time. Please try again later.");
                console.log('Error:', response.status);
                // You might want to handle different error cases here
            }
        } catch (error) {
            console.log('Network error or other exception:', error);
            // Handle network errors or other exceptions here
        }
    };

    // affichage
    return (
        <div key={data.id} className='item'>
            {!deleteType &&
                <>
                    <p>{data.name}</p>
                    {showEditPopup &&
                        <>
                            {!ediType &&
                                <form onSubmit={handleEdit}>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        className='ediInput'
                                        placeholder='Name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </form>
                            }
                        </>
                    }
                    <svg onClick={handleShowNav} className='actions' width="5" height="16" viewBox="0 0 5 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.00723 2.00098C4.00755 0.900976 3.10781 0.000714092 2.00781 0.000393294C0.907813 7.2497e-05 0.00755027 0.89981 0.00722947 1.99981C0.00690867 3.09981 0.906646 4.00007 2.00665 4.00039C3.10665 4.00071 4.00691 3.10098 4.00723 2.00098ZM4.00373 14.001C4.00405 12.901 3.10431 12.0007 2.00431 12.0004C0.904313 12.0001 0.00405066 12.8998 0.00372986 13.9998C0.00340906 15.0998 0.903147 16.0001 2.00315 16.0004C3.10315 16.0007 4.00341 15.101 4.00373 14.001ZM4.00548 8.00098C4.0058 6.90098 3.10606 6.00071 2.00606 6.00039C0.906063 6.00007 0.00580046 6.89981 0.00547967 7.99981C0.00515887 9.09981 0.904896 10.0001 2.0049 10.0004C3.1049 10.0007 4.00516 9.10098 4.00548 8.00098Z" fill="#194d8f" />
                    </svg>
                    {showNavPopup &&
                        <div className="actions_nav">
                            <span onClick={handleEdiPopup}>Edit</span>
                            <span onClick={handleSubmit}>Delete</span>
                        </div>
                    }
                </>
            }
        </div>
    )
}
