// import Image from "../../img/logoPlaceholder.png";
import { Link } from "react-router-dom";
import Image from "../../img/waveLogo.svg";
import './logo.css';

export default function Logo() {
    return (
        <div className="logo">
            <Link to="/" >
                <img src={Image} alt="Wave logo" />
            </Link>
        </div>
    );
}