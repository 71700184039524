import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Image from '../../img/imgLoggin.svg';
import Logo from '../../img/waveLogo.svg';
import './Loginform.css';


export default function LoginForm(props) {
    // state
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msgErr, setMsgErr] = useState('');

    // const [userNameIsValid, setUserNameIsValid] = useState();
    // const [passwordIsValid, setPasswordIsValid] = useState();
    const [formIsValid, setFormIsValid] = useState(false);
    const history = useNavigate();


    // comportements
    // useEffect(() => {
    //     const identifier = setTimeout(() => {
    //         setFormIsValid(
    //             email.trim().length > 6 && password.trim().length > 6
    //         );
    //     }, 500);

    //     return () => {
    //         clearTimeout(identifier);
    //     }
    // }, [email, password]);

    const userNameChangeHandler = (event) => {
        setEmail(event.target.value);
    }
    const passwordChangeHandler = (event) => {
        setPassword(event.target.value);
    }

    useEffect(() => {
        if (localStorage.getItem('user-info') && localStorage.getItem('bearer-token')) {
            props.onLogin(email, password);
        }
    }, [])
    async function handleSubmit(event) {
        event.preventDefault();

        let item = {
            email: email,
            password: password
        };
        let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(item)
        });
        if (response.status === 200) {
            // Login successful
            let result = await response.json();
            const token = result.token;
            localStorage.setItem('user-info', JSON.stringify(result));
            localStorage.setItem('bearer-token', token);
            props.onLogin(email, password);
        } else if (response.status === 401) {
            // Incorrect login credentials
            setMsgErr('Incorrect username or password');
        } else {
            // Other status codes
            console.log('Login failed: Unexpected error');
        }}
    // affichage
    return (
        <div className='content'>
            <div className='login-form'>
                <div className="login-img">
                    <img src={Image} alt="Image" />
                </div>
                <div className="login-right">
                    <h1>
                        <Link to="/">
                            <img src={Logo} alt="" />
                        </Link>
                    </h1>
                    <h2>Login</h2>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            required
                            onChange={userNameChangeHandler}
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            required
                            onChange={passwordChangeHandler}
                        />
                        <label htmlFor="remember_password">
                            <Link to="/forgot-password" >Forgot password ?</Link>
                        </label>
                        {msgErr != "" &&
                            <p className='errorMsg'>{msgErr}</p>
                        }
                        <div>
                            <button type="submit" className={`btn-submit`}>Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}