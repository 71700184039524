import './App.css';
import Logo from './components/Logo/Logo';
import DarkMode from './components/Buttons/DarkMode';
import LoginForm from './components/Forms/LoginForm';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import MainPage from './Pages/Home/MainPage';
import Sidebar from './components/Sidebar/Sidebar';
import TopNav from './components/TopNav/TopNav';
import ButtonLogOut from './components/Buttons/ButtonLogOut';
import Users from './Pages/Users/Users';
import AddUsers from './Pages/AddUsers/AddUsers';
import EditUser from './Pages/EditUser/EditUser';
import ForgotPassword from './components/Forms/ForgotPassword';
import Profile from './Pages/Profile/Profile';
import Vacations from './Pages/Vacations/Vacations';

import AttachmentTypes from './Pages/Settings/AttachmentTypes';
import PositionTypes from './Pages/Settings/PositionTypes';
import ContractTypes from './Pages/Settings/ContractTypes';
import ExperienceLevels from './Pages/Settings/ExperienceLevels';
import FamilyStatus from './Pages/Settings/FamilyStatus';
import Nationalities from './Pages/Settings/Nationalities';
import VacationTypes from './Pages/Settings/VacationTypes';
import ResetPassword from './components/Forms/ResetPassword';


function App() {
  // state
  const nav = useNavigate();
  const [darkMode, setDarkMode] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState('Admin Dashboard'); // Default page name
  const [userData, setUserData] = useState([]);

  console.log('test');

  console.log(localStorage ? localStorage: '');

  // comportements
  // ** API get current user
  async function getUser() {
    const userInfo = localStorage.getItem('user-info');
    const userInfoObject = JSON.parse(userInfo);
    console.log(userInfoObject ? userInfoObject.user.role : '');
    console.log('userInfoObject');
    const bearerToken = localStorage.getItem('bearer-token');
    try {

      if (bearerToken) {

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearerToken}`
          }
        });
        if (response.ok) {
          const user = await response.json();
          setUserData(user);
        } else {
          console.log('Error retrieving users:', response.status);
        }
      }
    } catch (error) {
      console.log('Error:', error);
    }
  }

  const darkModeHandler = () => {
    setDarkMode((current) => !current);
  }

  useEffect(() => {
    const logginInfos = localStorage.getItem('isLoggedIn');

    if (logginInfos === '1') {
      setIsLoggedIn(true);
    }
  }, []);

  const loginHandler = (userName, password) => {
    localStorage.setItem('isLoggedIn', '1');
    setIsLoggedIn(true);
    // API current user fucntions
    getUser();
  };

  const logoutHandler = () => {
    localStorage.removeItem('isLoggedIn', '1');
    localStorage.removeItem('user-info');
    localStorage.removeItem('bearer-token')
    setIsLoggedIn(false);
    nav("/"); // Navigate to the '/' path
  };

  // affichage
  return (
    <div className={`app ${darkMode ? 'app-dark-mode' : ''} ${isLoggedIn ? '' : 'app-logout'} ${isLoggedIn ? 'userLoggedIn' : ''}`}>
      {isLoggedIn &&
        <div className='sidebar'>
          <Logo />
          <Sidebar userData={userData} />
          {/* <ButtonLogOut onLogOut={logoutHandler} /> */}

          <DarkMode handleToggle={darkModeHandler} darkMode={darkMode} />
        </div>
      }
      {!isLoggedIn &&
        <Routes>
          <Route
            exact
            path='/'
            element={<LoginForm onLogin={loginHandler} setPage={setCurrentPage} />}
          />
          <Route
            exact
            path='/forgot-password'
            element={<ForgotPassword setPage={setCurrentPage} />}
          />
          <Route
            path='/password-reset'
            element={<ResetPassword setPage={setCurrentPage} />}
          />
        </Routes>
      }
      {isLoggedIn &&
        <div>
          <TopNav pageTitle={currentPage} onLogOut={logoutHandler} userData={userData} />
          <Routes>
            <Route
              exact
              path='/'
              element={<MainPage setPage={setCurrentPage} />}
            />
            <Route
              path='/users'
              element={<Users setPage={setCurrentPage} />}
            />
            <Route
              path='/profile'
              element={<Profile setPage={setCurrentPage} />}
            />
            <Route
              path='/users/add-user'
              element={<AddUsers setPage={setCurrentPage} />}
            />
            <Route
              path='/user/:id'
              element={<EditUser setPage={setCurrentPage} />} />
            <Route
              path='/vacations'
              element={<Vacations setPage={setCurrentPage} userData={userData} />} />
            <Route
              path='/attachment-types'
              element={<AttachmentTypes setPage={setCurrentPage} />} />
            <Route
              path='/position-types'
              element={<PositionTypes setPage={setCurrentPage} />} />
            <Route
              path='/contract-types'
              element={<ContractTypes setPage={setCurrentPage} />} />
            <Route
              path='/experience-levels'
              element={<ExperienceLevels setPage={setCurrentPage} />} />
            <Route
              path='/family-status'
              element={<FamilyStatus setPage={setCurrentPage} />} />
            <Route
              path='/nationalities'
              element={<Nationalities setPage={setCurrentPage} />} />
            <Route
              path='/vacation-types'
              element={<VacationTypes setPage={setCurrentPage} />} />
          </Routes>
        </div>
      }
    </div>
  );
}

export default App;
