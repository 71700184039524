import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";


export default function UserRow({ data }) {
    // state
    const [isEditUserPopupVisible, setisEditUserPopupVisible] = useState(false);
    const popupRef = useRef(null);

    // comportement
    const bearerToken = localStorage.getItem('bearer-token');
    
    const handleEditUserPopup = () => {
        setisEditUserPopupVisible(!isEditUserPopupVisible);
    };

    // useEffect(() => {
    //     const handleDocumentClick = (event) => {
    //         // Check if the clicked element is not part of the popup
    //         if (popupRef.current && !popupRef.current.contains(event.target)) {
    //             setisEditUserPopupVisible(false);
    //         }
    //     };

    //     // Attach the event listener
    //     document.addEventListener('click', handleDocumentClick);

    //     // Clean up the event listener when the component unmounts
    //     return () => {
    //         document.removeEventListener('click', handleDocumentClick);

    //     };
    // }, []);

    // ** enable use API
    const enableUser = async () => {
        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/enable/${data.id}`, {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                // Perform any necessary state updates or notifications
            } else {
                console.log('Error enabling user:', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };

    // ** disable use API
    const disableUser = async () => {
        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/disable/${data.id}`, {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                // Perform any necessary state updates or notifications
            } else {
                console.log('Error disabling user:', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };

    // ** generate password use API
    const generatePassword = async () => {

        const userEmail = {
            email: data.email
        }

        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/forgot-password`, {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                },
                body: JSON.stringify(userEmail),
            });

            if (response.ok) {
                // Perform any necessary state updates or notifications
                console.log(response)
            } else {
                console.log('Error disabling user:', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };

    // affichage
    return (
        <>
            <tr key={data.id}>
                <td className='user_detail'>
                    <div>
                        <p>{data.first_name} {data.last_name}</p>
                        <span>{data.role}</span>
                    </div>
                </td>
                <td className='user_name'>
                    <p>{data.phone_number}</p>
                </td>
                <td>
                    <p>
                        {data && data.positions && data.positions.length > 0
                            ? data.positions[0].position_type && data.positions[0].position_type.name
                                ? data.positions[0].position_type.name
                                : ''
                            : ''}
                    </p>
                    <span>{data.experience_level.name}</span>
                </td>
                <td className='date'>
                    <p>
                        {data && data.contracts && data.contracts.length > 0
                            ? data.contracts[0].contract_type && data.contracts[0].contract_type.name
                                ? data.contracts[0].contract_type.name
                                : ''
                            : ''}
                    </p>
                </td>
                <td className={`priority ${data.priority === 'low' ? 'low-priority' : ''}${data.priority === 'high' ? 'high-priority' : ''}${data.priority === 'normal' ? 'normal-priority' : ''}`}>
                    <p>
                        {data && data.salaries && data.salaries.length > 0
                            ? data.salaries[0].salary + ' DH'
                            : ''}
                    </p>
                </td>
                <td className='other_details'>
                    <div
                        // ref={popupRef}
                        onClick={handleEditUserPopup}
                        className={`action ${isEditUserPopupVisible ? 'reverseIcon' : ''}`}
                    >
                        Actions
                        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.94 0.5L4 3.59042L7.06 0.5L8 1.45142L4 5.5L0 1.45142L0.94 0.5Z" fill="#1C5DAF" />
                        </svg>
                    </div>
                    <div
                        // ref={popupRef}
                        className={`popupEditUser ${isEditUserPopupVisible ? 'show' : ''}`}
                    >
                        <span>
                            <Link to={`/user/${data.id}`}>
                                Edit
                            </Link>
                        </span>
                        <span onClick={enableUser}>Enable</span>
                        <span onClick={disableUser}>Disable</span>
                        <span onClick={generatePassword}>Regenerate Password</span>
                    </div>
                </td>
            </tr>
        </>
    )
}
