import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Image from '../../img/imgLoggin.svg';
import Logo from '../../img/waveLogo.svg';
import './Loginform.css';

export default function ResetPassword() {
    // state
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    // ** Get the value of a specific parameter
    const token = searchParams.get('token');
    const email = searchParams.get('email');
    // console.log(token,email);


    // comportement
    const bearerToken = localStorage.getItem('bearer-token');

    // ** reset password use API
    async function handleSubmit(event) {
        event.preventDefault();

        const userEmail = {
            email: email,
            token: token,
            password: password,
            password_confirmation: passwordConfirmation,
        }

        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/reset-password`, {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                },
                body: JSON.stringify(userEmail),
            });

            if (response.ok) {
                // Perform any necessary state updates or notifications
                console.log(response)
                console.log('succes !!')
            } else {
                console.log('Error disabling user:', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };


    // affichage
    return (
        <div className='content'>
            <div className='login-form'>
                <div className="login-img">
                    <img src={Image} alt="Image" />
                </div>
                <div className="login-right">
                    <h1>
                        <Link to="/">
                            <img src={Logo} alt="" />
                        </Link>
                    </h1>
                    <h2>Reset your password</h2>
                    <form className='resetPass' onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                name="password"
                                placeholder="*******"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="password">Confirm password</label>
                            <input
                                type="password"
                                name="password"
                                placeholder="*******"
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                            />
                        </div>
                        <div>
                            <button type="submit" className={`btn-submit`}>Reset password</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
