import React, { useEffect, useState, useRef } from 'react';
import UpdateVacationPopup from "../../components/Popup/UpdateVacationPopup";
import axios from 'axios';
import CloseButton from '../../components/Buttons/CloseButton';



export default function VacationTable({ data, admin }) {
    // state
    const [isEditVacationPopupVisible, setisEditVacationPopupVisible] = useState(false);
    const [showCommentPopupAccept, setShowCommentPopupAccept] = useState(false);
    const [showCommentPopupReject, setShowCommentPopupReject] = useState(false);
    const [showCommentPopupUpdate, setShowCommentPopupUpdate] = useState(false);
    const [showCommentPopupCancel, setShowCommentPopupCancel] = useState(false);
    const [comment, setComment] = useState('');
    const [msgErr, setMsgErr] = useState('');
    const [msgAccept, setMsgAccept] = useState('');
    const [msgReject, setMsgReject] = useState('');
    const [msgCancel, setMsgCancel] = useState('');

    // comportement
    const bearerToken = localStorage.getItem('bearer-token');

    const handleEditVacationPopup = () => {
        setisEditVacationPopupVisible(!isEditVacationPopupVisible);
    };

    // ** API accept vacation
    const handleAccept = async (e) => {
        e.preventDefault();

        try {


            const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/vacations/accept/${data.id}`,

                {
                    comment: comment,
                }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                },
            });

            if (response.status >= 200 && response.status < 300) {
                // Accepted
                console.log('Accepted!');
                setMsgAccept('Vacation request accepted. The employee has been notified.');
            } else {
                setMsgErr("Sorry, we couldn't process the vacation request at this time. Please try again later.");
                console.log('Error:', response.status);
                // You might want to handle different error cases here
            }
        } catch (error) {
            console.log('Network error or other exception:', error);
            // Handle network errors or other exceptions here
        }
    };

    // ** API reject vacation
    const handleReject = async (e) => {
        e.preventDefault();

        try {


            const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/vacations/reject/${data.id}`, {

                comment: comment,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                },
            });

            if (response.status >= 200 && response.status < 300) {
                // Accepted
                console.log('Rejected!');
                setMsgReject('Vacation request rejected. The employee has been notified.');
            } else {
                setMsgErr('We encountered an issue while rejecting the vacation request. Please try again');
                console.log('Error:', response.status);
                // You might want to handle different error cases here
            }
        } catch (error) {
            console.log('Network error or other exception:', error);
            // Handle network errors or other exceptions here
        }
    };

    // ** API cancel vacation
    const handleCancel = async (e) => {
        e.preventDefault();

        try {


            const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/vacations/cancel/${data.id}`, {

                comment: comment,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                },
            });

            if (response.status >= 200 && response.status < 300) {
                // Accepted
                console.log('Cancled!');
                setMsgCancel('acation request canceled successfully.');
            } else {
                setMsgErr('We were unable to cancel the vacation request. Please try again');
                console.log('Error:', response.status);
                // You might want to handle different error cases here
            }
        } catch (error) {
            console.log('Network error or other exception:', error);
            // Handle network errors or other exceptions here
        }
    };

    const handleShowPopupAccept = () => {
        setShowCommentPopupAccept(!showCommentPopupAccept);
    }

    const handleShowPopupReject = () => {
        setShowCommentPopupReject(!showCommentPopupReject);
    }

    const handleShowPopupUpdate = () => {
        setShowCommentPopupUpdate(!showCommentPopupUpdate);
    }

    const handleShowPopupCancel = () => {
        setShowCommentPopupCancel(!showCommentPopupCancel);
    }

    console.log(data)

    // affichage
    return (
        <>
            <tbody>
                <tr>
                    {admin == "true" &&
                        <td>
                            <div>
                                <p>{data.user?.first_name} {data.user?.last_name}</p>
                            </div>
                        </td>
                    }
                    <td>
                        <div>
                            <p>{data.start_date}</p>
                            <span>
                                {data.start_is_half_day &&
                                    <>
                                        Afternoon
                                    </>
                                }
                                {!data.start_is_half_day &&
                                    <>
                                        Morning
                                    </>
                                }
                            </span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <p>{data.end_date}</p>
                            <span>
                                {data.end_is_half_day &&
                                    <>
                                        Afternoon
                                    </>
                                }
                                {!data.end_is_half_day &&
                                    <>
                                        Morning
                                    </>
                                }
                            </span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <p>{data.duration}</p>
                        </div>
                    </td>
                    <td>
                        <div>
                            <p>{data.comment}</p>
                        </div>
                    </td>
                    <td>
                        <div>
                            <p>{data.status}</p>
                        </div>
                    </td>
                    <td className='other_details'>
                        {data.status === "pending" &&
                            <div
                                onClick={handleEditVacationPopup}
                                className={`action ${isEditVacationPopupVisible ? 'reverseIcon' : ''}`}
                            >
                                Actions
                                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.94 0.5L4 3.59042L7.06 0.5L8 1.45142L4 5.5L0 1.45142L0.94 0.5Z" fill="#1C5DAF" />
                                </svg>
                            </div>
                        }

                        {data.status !== "pending" &&
                            <div
                                className={`action noAction`}
                            >
                                Actions
                                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.94 0.5L4 3.59042L7.06 0.5L8 1.45142L4 5.5L0 1.45142L0.94 0.5Z" fill="#828282" />
                                </svg>
                            </div>
                        }

                        <div className={`popupEditUser ${isEditVacationPopupVisible ? 'show' : ''}`}>
                            {data.status === "pending" &&
                                <>
                                    {admin == "true" &&
                                        <>
                                            <span onClick={handleShowPopupAccept}>Accept</span>
                                            <span onClick={handleShowPopupReject} >Reject</span>
                                        </>
                                    }
                                    {admin == "false" &&
                                        <>
                                            <span onClick={handleShowPopupUpdate} >Update</span>
                                            <span onClick={handleShowPopupCancel} >Cancel</span>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className='td_popup'>
                        {(showCommentPopupAccept || showCommentPopupReject || showCommentPopupCancel) && (
                            <div className="">
                                <div className="overlay"></div>

                                <form className='form_create_vacation' >
                                    <div className='popup-comment-vacation'>
                                        <div>
                                            <label htmlFor="comment">Comment</label>
                                            <textarea
                                                name="comment"
                                                id="comment"
                                                cols="30"
                                                rows="10"
                                                value={comment}
                                                onChange={(e) => setComment(e.target.value)}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                    <div className="group_btn">
                                        {showCommentPopupAccept &&
                                            <button onClick={handleAccept}>Accept</button>
                                        }
                                        {showCommentPopupReject &&
                                            <button onClick={handleReject}>Reject</button>
                                        }
                                        {showCommentPopupCancel &&
                                            <button onClick={handleCancel}>Cancel vacation</button>
                                        }
                                        <button type='cancel'>Cancel</button>
                                    </div>
                                    {msgErr != "" &&
                                        <p className='errorMsg center mr-t-10'>{msgErr}</p>
                                    }
                                    {msgAccept != "" &&
                                        <p className='validateMsg center mr-t-10'>{msgAccept}</p>
                                    }
                                    {msgReject != "" &&
                                        <p className='validateMsg center mr-t-10'>{msgReject}</p>
                                    }
                                    {msgCancel != "" &&
                                        <p className='validateMsg center mr-t-10'>{msgCancel}</p>
                                    }
                                </form>
                            </div>
                        )}
                        {showCommentPopupAccept && (
                            <CloseButton onClick={handleShowPopupAccept} />
                        )}

                        {showCommentPopupReject && (
                            <CloseButton onClick={handleShowPopupReject} />
                        )}
                        {showCommentPopupUpdate && (
                            <>
                                <UpdateVacationPopup id={data.id} />
                                <CloseButton onClick={handleShowPopupUpdate} />
                            </>
                        )}
                        {showCommentPopupCancel && (
                            <CloseButton onClick={handleShowPopupCancel} />
                        )}
                    </td>
                </tr>
            </tbody>

        </>
    )
}
