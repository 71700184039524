import React, { useEffect } from 'react';
import './Users.css';
import User from '../../containers/Users/User';
import Teachers from '../../teachers.json';

export default function Users({ setPage }) {
    // comportements
    useEffect(() => {
        setPage('Users'); // Set the page name when the component mounts
    }, [setPage]);

    // affichage
    return (
        <div className='page internPage'>
            <div className='page-users contentPage'>
                <User />
            </div>
        </div>
    )
}
