import React, { useEffect } from 'react';
import './Adduser.css';
import AddUserForm from '../../components/Forms/AddUserForm';

export default function AddUsers({ setPage }) {
    // comportements
    useEffect(() => {
        setPage('Add User'); // Set the page name when the component mounts
    }, [setPage]);

    // affichage
    return (
        <div className='page internPage'>
            <div className='page-add-user contentPage'>
               <AddUserForm />
            </div>
        </div>
    )
}
