import React, { useEffect, useState } from 'react';
import './User.css';
import svg1 from '../../img/sort.svg';
import svg2 from '../../img/filter.svg';
import UserRow from '../../components/User/UserRow';
import ButtonAddUser from '../../components/Buttons/ButtonAddUser';


export default function User() {
    // state
    // ** pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(7); 


    // ** sort priority
    // const [sortOrder, setSortOrder] = useState('asc');
    // const [sortedData, setSortedData] = useState([]);

    // ** Filter
    // const [filteredData, setFilteredData] = useState([]);

    // ** show hide popup
    // const [isFilterPopupVisible, setIsFilterPopupVisible] = useState(false);

    // ** user data
    const [userData, setUserData] = useState([]);

    // comportement 

    // ** sort priority
    // const sortByPriority = () => {
    //     const sortedDataCopy = [...sortedData].sort((a, b) => {
    //         const priorityOrder = {
    //             high: 0,
    //             normal: 1,
    //             low: 2,
    //         };

    //         const priorityA = priorityOrder[a.priority];
    //         const priorityB = priorityOrder[b.priority];

    //         if (sortOrder === 'asc') {
    //             return priorityA - priorityB;
    //         } else {
    //             return priorityB - priorityA;
    //         }
    //     });

    //     setSortedData(sortedDataCopy);
    //     setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    // };

    // ** Filter 
    // *** Show hide popupx
    // const handleFilter = () => {
    //     const highChecked = document.getElementById('highCheckbox').checked;
    //     const normalChecked = document.getElementById('normalCheckbox').checked;
    //     const lowChecked = document.getElementById('lowCheckbox').checked;

    //     const filteredData = sortedData.filter(item => {
    //         if (highChecked && item.priority === 'high') {
    //             return true;
    //         }
    //         if (normalChecked && item.priority === 'normal') {
    //             return true;
    //         }
    //         if (lowChecked && item.priority === 'low') {
    //             return true;
    //         }
    //         return false;
    //     });

    //     setFilteredData(filteredData);
    //     setIsFilterPopupVisible(!isFilterPopupVisible);
    // };


    // ** API get users
    async function getUsers() {
        const bearerToken = localStorage.getItem('bearer-token');
        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                const users = await response.json();
                setUserData(users);
            } else {
                console.log('Error retrieving users:', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }
    useEffect(() => {
        getUsers();
    }, []);


    // calc pagination
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const totalPages = Math.ceil(userData.length / rowsPerPage);

    // ** pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setCurrentPage(1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };


    // affichage
    return (
        <>
            <div className='Top_bar'>
                <h2>All users</h2>
                <div className='items'>
                    {/* <div className='item' onClick={sortByPriority}>
                        <img src={svg1} alt="icon" />
                        Sort
                    </div>
                    <div className="item" onClick={handleFilter}>
                        <img src={svg2} alt="icon" />
                        Filter
                        <div className={`popup-filter ${isFilterPopupVisible ? 'show' : ''}`}>
                            <label htmlFor="highCheckbox">
                                <input type="checkbox" id="highCheckbox" />
                                High
                            </label>
                            <label htmlFor="normalCheckbox">
                                <input type="checkbox" id="normalCheckbox" />
                                Normal
                            </label>
                            <label htmlFor="lowCheckbox">
                                <input type="checkbox" id="lowCheckbox" />
                                Low
                            </label>
                            <button onClick={handleFilter}>Apply Filter</button>
                        </div>
                    </div> */}
                    <ButtonAddUser />
                </div>
            </div>
            <div className='User_listing'>
                <div className='user'>
                    <table>
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Phone Number</th>
                                <th>Position</th>
                                <th>Contract</th>
                                <th>Salary</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userData.slice(startIndex, endIndex).map(data => (
                                <UserRow data={data} key={data.id} />
                            ))}
                        </tbody>
                    </table>
                    <div className='pagination'>
                        <div className='rows-per-page'>
                            <label>Rows per page:</label>
                            <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                                <option value={7}>7</option>
                                <option value={10}>10</option>
                            </select>
                        </div>
                        <div className='nav-pagination'>
                            <span> {startIndex + 1}-{endIndex} of {userData.length} </span>
                            <div className='buttons'>
                                <button
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1}
                                >
                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 13L1.07071 7.07071C1.03166 7.03166 1.03166 6.96834 1.07071 6.92929L7 1" stroke="#9FA2B4" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </button>
                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages}
                                >
                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 13L6.92929 7.07071C6.96834 7.03166 6.96834 6.96834 6.92929 6.92929L1 1" stroke="#9FA2B4" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
