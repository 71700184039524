import React, { useEffect, useState, useRef } from 'react';
import { Calendar } from 'react-date-range';

import axios from 'axios';
import { subYears, parseISO } from 'date-fns';
import format from 'date-fns/format';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file



export default function UpdateVacationPopup({ id }) {
    // state
    const [vacationTypes, setVacationTypes] = useState([]);
    const [vacationType, setVacationType] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startHalfDay, setStartHalfDay] = useState('');
    const [endHalfDay, setEndHalfDay] = useState('');
    const [msgErr, setMsgErr] = useState('');
    const [msgValidate, setMsgValidate] = useState('');

    const [calendar, setCalendar] = useState('');
    const [calendar2, setCalendar2] = useState('');
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    // comportement
    const bearerToken = localStorage.getItem('bearer-token');
    // ** API get vacation types
    async function getVacationTypes() {
        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/vacation-types`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                const vacationTypeD = await response.json();
                setVacationTypes(vacationTypeD);

            } else {
                console.log('Error retrieving :', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    // ** API get vacation by id
    async function getVacation() {
        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/vacations/${id}`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                const vacationID = await response.json();
                setVacationType(vacationID.vacation_type?.id);
                setStartDate(vacationID.start_date);
                setEndDate(vacationID.end_date);
                setStartHalfDay(vacationID.start_is_half_day);
                setEndHalfDay(vacationID.end_is_half_day);
            } else {
                console.log('Error retrieving :', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }
    // ** API update vacation
    const handleUpdate = async (e) => {
        e.preventDefault();

        try {


            const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/vacations/${id}`, {

                vacation_type_id: parseInt(vacationType),
                start_date: startDate,
                end_date: endDate,
                start_is_half_day: Boolean(startHalfDay),
                end_is_half_day: Boolean(endHalfDay),
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                },
            });

            if (response.status >= 200 && response.status < 300) {
                // Accepted
                console.log('Updated!');
                setMsgValidate('Vacation request updated successfully! The changes have been saved.');
            } else {
                setMsgErr('We encountered an issue while updating your vacation request. Please review your changes again!');

                console.log('Error:', response.status);
                // You might want to handle different error cases here
            }
        } catch (error) {

            console.log('Network error or other exception:', error);
            // Handle network errors or other exceptions here
        }
    };
    // ** date picker
    const refOne = useRef(null);
    const refTwo = useRef(null);
    useEffect(() => {
        // API functions
        getVacationTypes();
        getVacation();

        // Calendar
        setCalendar(format(new Date(), 'yyyy-MM-dd'));
        setCalendar2(format(new Date(), 'yyyy-MM-dd'));
        document.addEventListener("keydown", hideOnEscape, true);
        document.addEventListener("click", hideOnClickOutside, true);
    }, []);
    // *** hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key == "Escape") {
            setOpen(false);
            setOpen2(false);
        }
    }
    // *** hide on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false);
        }
        if (refTwo.current && !refTwo.current.contains(e.target)) {
            setOpen2(false);
        }
    }

    // affichage
    return (
        <>
            <div className="overlay"></div>
            <form className='form_create_vacation update'>
                <div className='popup-create-vacation'>
                    <div>
                        <label htmlFor="vacation_type">Vacation Type</label>

                        <select
                            name="vacation_type"
                            id="vacation_type"
                            required="required"
                            value={vacationType}
                            onChange={(e) => setVacationType(e.target.value)}
                        >
                            <option value="0">Vacation type</option>
                            {(vacationTypes.length > 0) ? (
                                vacationTypes.map(data => (
                                    <option key={data.id} value={data.id}>{data.name}</option>
                                ))
                            ) : (
                                <option value="0">Loading vacation types...</option>
                            )}
                        </select>
                    </div>

                    <div>
                        <label htmlFor="start date">Strat Date</label>
                        <input
                            value={calendar}
                            name="start date"
                            id="start date"
                            required="required"
                            readOnly
                            onClick={() => setOpen((open) => !open)}
                        />
                        <div ref={refOne}>
                            {open &&
                                <Calendar
                                    date={subYears(new Date(), 18)}
                                    value={calendar}
                                    onChange={(date) => {
                                        setCalendar(format(date, 'yyyy-MM-dd'));
                                        setStartDate(format(date, 'yyyy-MM-dd')); // Update the start date state
                                    }}
                                    minDate={new Date()}
                                />}
                        </div>
                    </div>
                    <div>
                        <label htmlFor="end_date">End Date</label>
                        <input
                            value={calendar2}
                            name="end_date"
                            id="end_date"
                            required="required"
                            readOnly
                            onClick={() => setOpen2((open2) => !open2)}
                        />
                        <div ref={refTwo}>
                            {open2 &&
                                <Calendar
                                    date={subYears(new Date(), 18)}
                                    value={calendar2}
                                    onChange={(date) => {
                                        setCalendar2(format(date, 'yyyy-MM-dd'));
                                        setEndDate(format(date, 'yyyy-MM-dd')); // Update the end date state
                                    }}
                                    minDate={new Date()}
                                />}
                        </div>
                    </div>
                    <div>
                        <label htmlFor="start_is_half_day">Start half day</label>
                        <select
                            name="start_is_half_day"
                            id="start_is_half_day"
                            required="required"
                            value={startHalfDay}
                            onChange={(e) => setStartHalfDay(e.target.value)}
                        >
                            <option value="0">strat half day</option>
                            <option value={false}>No</option>
                            <option value={true}>Yes</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="end_is_half_day">End half day</label>
                        <select
                            name="end_is_half_day"
                            id="end_is_half_day"
                            required="required"
                            value={endHalfDay}
                            onChange={(e) => setEndHalfDay(e.target.value)}
                        >
                            <option value="0">end half day</option>
                            <option value={false}>No</option>
                            <option value={true}>Yes</option>
                        </select>
                    </div>

                </div>
                <button onClick={handleUpdate}>Update vacation</button>
                {msgErr != "" &&
                    <p className='errorMsg center mr-t-10'>{msgErr}</p>
                }
                {msgValidate != "" &&
                    <p className='validateMsg center mr-t-10'>{msgValidate}</p>
                }
            </form>
        </>
    )
}
