import React, { useEffect, useState } from 'react';
import Image from '../../img/Avatar.png';
import Image4 from '../../img/man5-512.webp';
import Image2 from '../../img/bell-solid.svg';
import Image3 from '../../img/iconLogout.svg';
import ButtonLogOut from '../Buttons/ButtonLogOut';
import './Topnav.css';


export default function TopNav({ pageTitle, onLogOut, userData }) {

    // comportement
    // ** log out btn
    const handleLogout = async () => {
        const bearerToken = localStorage.getItem('bearer-token');
        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/logout`, {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                // Perform necessary logout actions
                onLogOut();
                console.log('Logged out successfully');
            } else {
                console.log('Error logging out:', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };


    // affichage
    return (
        <div className="top_nav">
            <div>
                <div className="breadcrumb">
                    <span>Wave / Users</span>
                </div>
                <h1>{pageTitle}</h1>
            </div>
            <div className='nav-right'>
                <div className="notifications">
                    <img className='icon' src={Image2} alt="bell" />
                </div>
                <div className="current_user">
                    {/* <form>
                        <input type="search" placeholder='Search' name="search" id="search" />
                    </form> */}
                    <div className="balance">
                        {userData.balance} balance
                    </div>
                    <div className='right-part'>
                        <div className="admin-account">
                            <h2>
                                {userData.first_name} {userData.last_name}
                            </h2>
                            {userData.sexe === "female" &&
                                <img className='avatar' src={Image} alt="Avatar" />
                            }
                             {userData.sexe === "male" &&
                                <img className='avatar' src={Image4} alt="Avatar" />
                            }
                        </div>
                    </div>
                </div>
                <div className="logoutIcon" onClick={handleLogout}>
                    <img src={Image3} alt="log out icon" />
                </div>
            </div>
        </div>
    );
}