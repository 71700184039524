import React from 'react';
import './Datacharts.css'
import ArrowTop from '../../img/arrowTop.svg';

export default function DataCharts({ data }) {
    return (
        <>
            {data.map(data => (
                <div className='Card_chart' key={data.id}>
                    <div className='Card_chart-info'>
                        <h4>{data.title}</h4>
                        <span>{data.number}</span>
                        <p>
                            <img src={ArrowTop} alt="arrowTop" />
                            {data.pourcentage}% Since yesterday
                        </p>
                    </div>
                    <div className='Card_chart-img'>
                        <img src={data.image} alt="chart" />
                    </div>
                </div>
            ))}
        </>
    )
}
