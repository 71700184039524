import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { subYears, parseISO } from 'date-fns';
import format from 'date-fns/format';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "./Adduserform.css";
import "./Edituserform.css";


export default function EditUserPosition({ userId }) {
    // state
    const [position, setPosition] = useState('');
    const [start_date, setStartDate] = useState('');
    const [comment, setComment] = useState('');
    const [calendar, setCalendar] = useState('');
    const [open, setOpen] = useState(false);
    const [positionTypes, setPositionTypes] = useState([]);
    const [userPosition, setUserPosition] = useState([]);
    const [msgErr, setMsgErr] = useState('');
    const [msgValidate, setMsgValidate] = useState('');

    // comportement
    const bearerToken = localStorage.getItem('bearer-token');

    // ** API get User by id
    async function getUsers() {
        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}/positions`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });


            if (response.ok) {
                const userPositionData = await response.json();
                setUserPosition(userPositionData);
            } else {
                console.log('Error retrieving :', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    // ** API get Positions types
    async function getPositionTypes() {
        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/position-types`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                const allPositionTypes = await response.json();
                setPositionTypes(allPositionTypes);
            } else {
                console.log('Error retrieving :', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    // ** API update user's position
    const handleSubmitPosition = async (e) => {
        e.preventDefault();

        const userPosition = {
            position_type_id: position,
            start_date: start_date,
            comment: comment
        }

        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}/positions`, {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                },
                body: JSON.stringify(userPosition)
            });


            if (response.ok) {
                setPosition('');
                setStartDate('');
                setComment('');
                console.log('succes');
                setMsgValidate('Position added successfully!');
            } else {
                setMsgErr('Error updating position!');
                console.log('Error creating user:', response.status);
            }

        } catch (error) {
            console.log('Error:', error);
        }
    }

    // ** date picker start date
    const refOne = useRef(null);

    useEffect(() => {
        // Calendar
        setCalendar(format(new Date(), 'yyyy-MM-dd'));
        document.addEventListener("keydown", hideOnEscape, true);
        document.addEventListener("click", hideOnClickOutside, true);

        // API functions 
        getUsers();
        getPositionTypes();
    }, []);
    // *** hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key == "Escape") {
            setOpen(false);
        }
    }
    // *** hide on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false);
        }
    }

    // affichage
    return (
        <>
            <form onSubmit={handleSubmitPosition}>
                <div className='add_user_form edit-position'>
                    <div>
                        <label htmlFor="position">Position</label>
                        <select
                            name="position"
                            id="position"
                            required="required"
                            value={position}
                            onChange={(e) => setPosition(e.target.value)}
                        >
                            <option value="0">Position</option>
                            {positionTypes.map(data => (
                                <option key={data.id} value={data.id}>{data.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='start_date'>
                        <label htmlFor="start_date">Start date</label>
                        <input
                            value={calendar}
                            name="start_date"
                            id="start_date"
                            required="required"
                            readOnly
                            onClick={() => setOpen((open) => !open)}
                        />
                        <div ref={refOne}>
                            {open &&
                                <Calendar
                                    date={subYears(new Date(), 18)}
                                    value={calendar}
                                    onChange={(date) => {
                                        setCalendar(format(date, 'yyyy-MM-dd'));
                                        setStartDate(format(date, 'yyyy-MM-dd')); // Update the birthday state
                                    }}
                                    minDate={parseISO('2017-01-01')}
                                    maxDate={new Date()}
                                />}
                        </div>
                    </div>

                    <div>
                        <label htmlFor="comment">Comment</label>
                        <input
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            type="text"
                            name="comment"
                            id="comment"
                            placeholder='Comment'
                            required="required"
                        />
                    </div>
                </div>
                <button type='submit'>Add position</button>
                {msgErr != "" &&
                    <p className='errorMsg center mr-t-10'>{msgErr}</p>
                }
                {msgValidate != "" &&
                    <p className='validateMsg center mr-t-10'>{msgValidate}</p>
                }
            </form>
            <div className="all_history">
                <table>
                    <thead>
                        <tr>
                            <th>Position</th>
                            <th>Start Date</th>
                            <th>Comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userPosition.length > 0 &&
                            userPosition
                            .slice() // Create a copy of the array to avoid mutating the original
                            .sort((a, b) => a.start_date.localeCompare(b.start_date)) // Sort by start_date
                            .map(data => (
                                <tr key={data.id}>
                                    <td>
                                        <p>{data.position_type.name}</p>
                                    </td>
                                    <td>
                                        <p>{data.start_date}</p>
                                    </td>
                                    <td>
                                        <p>{data.comment}</p>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </>
    )
}
