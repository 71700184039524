import React from 'react';
import './Chart.css';

export default function Chart({ data }) {
    return (
        <>
            {data.map(chart => (
                <div
                    className='chart'
                    key={chart.id}
                    style={{ height: chart.pourcentage +'%' }}
                ></div>
            ))}
        </>
    )
}
