import React from 'react';
import "./Buttonadduser.css";
import { Link } from 'react-router-dom';

export default function ButtonAddUser() {
  return (
    <Link to="/users/add-user" className='btn_add_user'>
      Add User
    </Link>
  )
}
