import './Darkmode.css';

export default function DarkMode({ handleToggle, darkMode }) {

    // Affichage
    return (
        <button
            className='btn-dark-mode'
            onClick={handleToggle}>
            {darkMode ? "Light Mode" : "Dark Mode"}
        </button>
    );
}