import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EditUserForm from '../../components/Forms/EditUserForm';
import "./Edituser.css";
import EditUserSalary from '../../components/Forms/EditUserSalary';
import EditUserPosition from '../../components/Forms/EditUserPosition';
import EditUserContract from '../../components/Forms/EditUserContract';
import AddAttachments from '../../components/Forms/AddAttachments';

export default function EditUser({ setPage }) {
    const { id } = useParams();
    // state
    const [activeTab, setActiveTab] = useState("all_infos");

    // comportements
    useEffect(() => {
        setPage('Edit user');
    }, [setPage]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };


    // affichage
    return (
        <div className='page internPage'>
            <div className="tabs">
                <div
                    className={`tab all_infos ${activeTab === "all_infos" ? "active" : ""}`}
                    onClick={() => handleTabClick("all_infos")}
                >
                    Informations
                </div>
                <div
                    className={`tab salary ${activeTab === "salary" ? "active" : ""}`}
                    onClick={() => handleTabClick("salary")}
                >
                    Salary
                </div>
                <div
                    className={`tab position ${activeTab === "position" ? "active" : ""}`}
                    onClick={() => handleTabClick("position")}
                >
                    Position
                </div>
                <div
                    className={`tab contract ${activeTab === "contract" ? "active" : ""}`}
                    onClick={() => handleTabClick("contract")}
                >
                    Contract
                </div>
                <div
                    className={`tab contract ${activeTab === "attachments" ? "active" : ""}`}
                    onClick={() => handleTabClick("attachments")}
                >
                    Attachments
                </div>
            </div>
            <div className='page-edit-user contentPage'>
                {activeTab === "all_infos" &&
                    <EditUserForm userId={id} />
                }
                {activeTab === "salary" &&
                    <EditUserSalary userId={id} />
                }
                {activeTab === "position" &&
                    <EditUserPosition userId={id} />
                }
                {activeTab === "contract" &&
                    <EditUserContract userId={id} />
                }
                {activeTab === "attachments" &&
                    <AddAttachments userId={id} />
                }
            </div>
        </div>
    );
};
