import { useEffect, useState } from 'react';
import Image from '../../img/rightColumnImg.png';
import Chart from '../../components/Charts/Chart';
import charts from '../../charts.json';
import dataCharts from '../../data.json';
import './Mainpage.css';
import DataCharts from '../../containers/Home/DataCharts';
import BarChart from '../../containers/Home/BarChart';
import CircleCharts from '../../containers/Home/CircleCharts';
import circleChartData from '../../circlechart.json';


export default function MainPage({ setPage }) {

    // comportements
    useEffect(() => {
        setPage('Admin Dashboard');
    }, [setPage]);


    // affichage
    return (
        <div className='page home internPage'>
            <div className='first contentPage'>
                <div className='left'>
                    <div>
                        <h2>Welcome to your dashboard!</h2>
                        <p>This is BRBytes admin dashboard designed to reflect an overview of the most important events inside the team. Please add comments on what you want changed.</p>
                    </div>
                    <img src={Image} alt="Image" />
                </div>
                <div className='right'>
                    <h3>Chart title</h3>
                    <div className='charts'>
                        <Chart data={charts.charts}  />
                    </div>
                </div>
            </div>
            <div className='second'>
                <DataCharts data={dataCharts.dataCharts}/>
            </div>
            <div className='third'>
                <BarChart />
                <CircleCharts data={circleChartData.circleCharts} />
            </div>
        </div>
    );
}