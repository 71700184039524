import React, { useEffect, useState } from 'react';
import VacationTable from '../../containers/Vacations/VacationTable';
import "./Vacations.css";
import Img from "../../img/9264822.png";
import CreateVacationPopup from '../../components/Popup/CreateVacationPopup';
import CloseButton from '../../components/Buttons/CloseButton';



export default function Vacations({ setPage, userData }) {
    // state
    const [vacations, setVacations] = useState([]);
    const [isCreateVacationPopupVisible, setisCreateVacationPopupVisible] = useState(false);

    // comportements
    useEffect(() => {
        setPage('Vacations'); // Set the page name when the component mounts
    }, [setPage]);

    const handleCreateVacationPopup = () => {
        setisCreateVacationPopupVisible(!isCreateVacationPopupVisible);
    };

    // ** API get users
    async function getUsers() {
        const bearerToken = localStorage.getItem('bearer-token');
        try {

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/vacations`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                const vacation = await response.json();
                setVacations(vacation);
            } else {
                console.log('Error retrieving users:', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }
    useEffect(() => {
        getUsers();
    }, []);

    // affichage
    return (
        <div className='page internPage'>
            {vacations.length > 0 &&
                <div className='page-users contentPage'>
                    <div className='vacations_listing'>
                        <div className='user'>
                            <table>
                                <thead>
                                    <tr>
                                        {userData.role == "admin" &&
                                            <th>User</th>
                                        }
                                        <th>Date début</th>
                                        <th>Date fin</th>
                                        <th>Duré</th>
                                        <th>commentaire</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                {userData.role == "admin" &&
                                    vacations.map(data => (
                                        <VacationTable data={data} key={data.id} admin="true" />
                                    ))
                                }
                                {userData.role == "user" &&
                                    vacations.map(data => (
                                        <VacationTable data={data} key={data.id} admin="false" />
                                    ))
                                }
                            </table>
                        </div>
                    </div>
                    {userData.role == "user" &&
                        <>
                            <div className="add_vac" onClick={handleCreateVacationPopup} >
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1130_3256)">
                                        <path d="M0 8C0 7.44772 0.447715 7 1 7H15C15.5523 7 16 7.44772 16 8V8C16 8.55228 15.5523 9 15 9H1C0.447715 9 0 8.55228 0 8V8Z" fill="#4318FF" />
                                        <path d="M8.03125 0.00214235C8.58354 0.00311653 9.03046 0.451621 9.02949 1.0039L9.00479 15.0039C9.00382 15.5562 8.55531 16.0031 8.00303 16.0021V16.0021C7.45075 16.0011 7.00382 15.5526 7.00479 15.0004L7.02949 1.00038C7.03046 0.448093 7.47897 0.00116818 8.03125 0.00214235V0.00214235Z" fill="#4318FF" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1130_3256">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            {isCreateVacationPopupVisible &&
                                <>
                                    <CreateVacationPopup />
                                    <CloseButton onClick={handleCreateVacationPopup} />
                                </>
                            }
                        </>
                    }
                </div>
            }
            {vacations.length == 0 &&
                <div>
                    <div className='no_vac'>
                        <p>There's no vacation</p>
                        <button onClick={handleCreateVacationPopup} >Add New vacation</button>
                    </div>
                    <img className='no_vac_img' src={Img} alt="" />
                    {isCreateVacationPopupVisible &&
                        <>
                            <CreateVacationPopup />
                            <CloseButton onClick={setisCreateVacationPopupVisible} />
                        </>
                    }
                </div>
            }
        </div>
    )
}
