import React, { useEffect } from 'react';
import UserProfile from '../../components/User/UserProfile';

export default function Profile({ setPage }) {
    // comportements
    useEffect(() => {
        setPage('Profile'); // Set the page name when the component mounts
    }, [setPage]);

    // affichage
    return (
        <div className='page internPage'>
            <div className='page-edit-user profile-user contentPage'>
                <UserProfile />
            </div>
        </div>
    )
}
