import React, { useEffect, useState } from 'react';

import "./settingstyle.css";
import Types from '../../components/Types/Types';

export default function VacationTypes() {
    // state
    const [vacationTypes, setVacationTypes] = useState([]);
    const [name, setName] = useState([]);
    const [duration, setDuration] = useState([]);
    const [msgErr, setMsgErr] = useState('');
    const [msgValidate, setMsgValidate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(6);

    // comportement
    const bearerToken = localStorage.getItem('bearer-token');
    // ** API get Positions types
    async function getVacationTypes() {
        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/vacation-types`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                const allVacationTypes = await response.json();
                setVacationTypes(allVacationTypes);
            } else {
                console.log('Error retrieving :', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }
    useEffect(() => {
        getVacationTypes();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            name: name,
            duration: duration,
        };

        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/vacation-types`, {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                // Reset the form fields
                setName('');
                setDuration('');
                setMsgValidate('The new vacation type created successfully!');
                console.log('success !!!');

            } else {
                setMsgErr('Error creating a new vacation type! Please review the information and try again.');
                console.log('Error creating user:', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };
    // calc pagination
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const totalPages = Math.ceil(vacationTypes.length / rowsPerPage);
    // ** pagination
    const handlePrevPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };


    // affichage
    return (
        <div className='page internPage'>
            <div className='page-users contentPage'>
                <div className="settings">
                    <div className="allTypes">
                        <div className="head">All vacation types</div>
                        <div className="group">
                            {vacationTypes.slice(startIndex, endIndex).map(data => (
                                <Types key={data.id} data={data} api="vacation-types" />
                            ))}
                            {vacationTypes.length > 6 &&
                                <div className='pagination'>
                                    <button
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                    >
                                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 13L1.07071 7.07071C1.03166 7.03166 1.03166 6.96834 1.07071 6.92929L7 1" stroke="#2d5fa9" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </button>
                                    <div>{currentPage}</div>
                                    <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                    >
                                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 13L6.92929 7.07071C6.96834 7.03166 6.96834 6.96834 6.92929 6.92929L1 1" stroke="#2d5fa9" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="createType">
                        <h2>Create new vacation type</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form">
                                <div className='input'>
                                    <label htmlFor="name">Name of new type</label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        required
                                        placeholder='Name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div className='input'>
                                    <label htmlFor="">Duration</label>
                                    <input
                                        type="number"
                                        name="duration"
                                        id="duration"
                                        required
                                        placeholder='Duration'
                                        value={duration}
                                        onChange={(e) => setDuration(e.target.value)}
                                    />
                                </div>
                            </div>
                            <button type='submit'>Submit</button>
                            {msgErr != "" &&
                                <p className='errorMsg center mr-t-10'>{msgErr}</p>
                            }
                            {msgValidate != "" &&
                                <p className='validateMsg center mr-t-10'>{msgValidate}</p>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
