import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import "../Forms/Adduserform.css";
import "../Forms/Edituserform.css";
import "../../Pages/EditUser/Edituser.css";
import './Userprofile.css';



export default function UserProfile() {
    // state
    const [userData, setUserData] = useState({});
    const [userAddress, setUserAddress] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userSalary, setUserSalary] = useState('');
    const [userContract, setUserContract] = useState('');
    const [userPosition, setUserPosition] = useState('');
    const [msgErr, setMsgErr] = useState('');
    const [msgValidate, setMsgValidate] = useState('');

    const bearerToken = localStorage.getItem('bearer-token');

    // comportement
    // ** API get current user
    async function getUser() {
        const bearerToken = localStorage.getItem('bearer-token');
        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/user`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                const user = await response.json();
                setUserData(user);
                setUserAddress(user.address);
                setUserPhone(user.phone_number);
                setUserSalary(user.salaries[0]?.salary);
                setUserContract(user.contracts[0]?.contract_type?.name);
                setUserPosition(user.positions[0]?.position_type?.name);
            } else {
                console.log('Error retrieving users:', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }
    useEffect(() => {
        getUser();
    }, []);



    // ** API edit user
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {


            const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/user`, {

                phone_number: userPhone,
                address: userAddress,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                },
            });

            if (response.status >= 200 && response.status < 300) {
                // User successfully updated
                console.log('User updated successfully!');
                setMsgValidate('Your informations updated successfully!');
            } else {
                console.log('Error updating user:', response.status);
                // You might want to handle different error cases here
                setMsgErr('Error updating informations! Check your phone number or your address');
            }
        } catch (error) {
            console.log('Network error or other exception:', error);
            // Handle network errors or other exceptions here
        }
    };

    // affichage
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className='add_user_form edit-user profile'>
                    <div>
                        <label htmlFor="first_name">First Name</label>
                        <input
                            value={userData.first_name}
                            name="first_name"
                            id="first_name"
                            readOnly
                        />
                    </div>
                    <div>
                        <label htmlFor="last_name">Last Name</label>
                        <input
                            value={userData.last_name}
                            name="last_name"
                            id="last_name"
                            readOnly
                        />
                    </div>
                    <div>
                        <label htmlFor="role">Role</label>
                        <input
                            value={userData.role}
                            name="role"
                            id="role"
                            readOnly
                        />

                    </div>
                    <div>
                        <label htmlFor="email">Email</label>
                        <input
                            name="email"
                            id="email"
                            value={userData.email}
                            readOnly
                        />
                    </div>
                    <div>
                        <label htmlFor="cin">CIN</label>
                        <input
                            name="cin"
                            id="cin"
                            value={userData.cin}
                            readOnly
                        />
                    </div>
                    <div>
                        <label htmlFor="cnss">CNSS</label>
                        <input
                            value={userData.cnss_number}
                            name="cnss_number"
                            id="cnss_number"
                            readOnly
                        />
                    </div>
                    <div className='birthday_date'>
                        <label htmlFor="birthday">Birthday</label>
                        <input
                            value={userData.birthday}
                            name="birthday"
                            id="birthday"
                            readOnly
                        />
                    </div>
                    <div className='hiring_date'>
                        <label htmlFor="hiring_date">Hiring Date</label>
                        <input
                            value={userData.hiring_date}
                            name="hiring_date"
                            id="hiring_date"
                            readOnly
                        />
                    </div>
                    <div>
                        <label htmlFor="phone_number">Phone Number</label>
                        <input
                            name="phone_number"
                            id="phone_number"
                            value={userPhone}
                            onChange={(e) => setUserPhone(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="address">Address</label>
                        <input
                            name="address"
                            id="address"
                            value={userAddress}
                            onChange={(e) => setUserAddress(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="sexe">Sexe</label>
                        <input
                            name="sexe"
                            id="sexe"
                            value={userData.sexe}
                            readOnly
                        />
                    </div>
                    <div>
                        <label htmlFor="starting_balance">Starting balance</label>
                        <input
                            name="starting_balance"
                            id="starting_balance"
                            value={userData.starting_balance}
                            readOnly
                        />
                    </div>
                    <div>
                        <label htmlFor="experience_level">Experience Level</label>
                        <input
                            name="experience_level"
                            id="experience_level"
                            value={userData.experience_level?.name}
                            readOnly
                        />
                    </div>
                    <div>
                        <label htmlFor="family_status">Family Status</label>
                        <input
                            name="family_status"
                            id="family_status"
                            readOnly
                            value={userData.family_status?.name}
                        />
                    </div>
                    <div>
                        <label htmlFor="nationality">Nationality</label>
                        <input
                            name="nationality"
                            id="nationality"
                            value={userData.nationality?.name}
                            readOnly
                        />
                    </div>
                    <div>
                        <label htmlFor="position">Position</label>
                        <input
                            name="position"
                            id="position"
                            value={userPosition}
                            readOnly
                        />
                    </div>
                    <div>
                        <label htmlFor="salary">Salary</label>
                        <input
                            name="salary"
                            id="salary"
                            value={userSalary}
                            readOnly
                        />
                    </div>
                    <div>
                        <label htmlFor="contract">Contract</label>
                        <input
                            name="contract"
                            id="contract"
                            value={userContract}
                            readOnly
                        />
                    </div>
                </div>
                <button type="submit">Submit</button>
                {msgErr != "" &&
                    <p className='errorMsg center mr-t-10'>{msgErr}</p>
                }
                {msgValidate != "" &&
                    <p className='validateMsg center mr-t-10'>{msgValidate}</p>
                }
            </form>
        </>
    )
}
