import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Image from '../../img/imgLoggin.svg';
import Logo from '../../img/waveLogo.svg';
import './Loginform.css';


export default function ForgotPassword(props) {
    // state
    const [email, setEmail] = useState('');
    const [msgErr, setMsgErr] = useState('');
    const [msgValidate, setMsgValidate] = useState('');

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    // comportement
    const bearerToken = localStorage.getItem('bearer-token');

    // ** generate password use API
    async function handleSubmit(event) {
        event.preventDefault();

        const userEmail = {
            email: email
        }

        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/forgot-password`, {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                },
                body: JSON.stringify(userEmail),
            });

            if (response.ok) {
                // Perform any necessary state updates or notifications
                console.log(response)
                setMsgValidate('You will receive an email to change your password.');
            } else {
                setMsgErr('Error! check your email');
                console.log('Error disabling user:', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };


    // affichage
    return (
        <div className='content'>
            <div className='login-form'>
                <div className="login-img">
                    <img src={Image} alt="Image" />
                </div>
                <div className="login-right">
                    <h1>
                        <Link to="/">
                            <img src={Logo} alt="" />
                        </Link>
                    </h1>
                    <h2>Enter your email</h2>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={handleEmail}
                        />
                        <div>
                            <button type="submit" className={`btn-submit`}>Reset password</button>
                            {msgErr != "" &&
                                <p className='errorMsg center mr-t-10'>{msgErr}</p>
                            }
                            {msgValidate != "" &&
                                <p className='validateMsg center mr-t-10'>{msgValidate}</p>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}