import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { subYears, parseISO } from 'date-fns';
import format from 'date-fns/format';
import "./Adduserform.css";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file



export default function AddUserForm() {
    // state
    const [familyStatus, setFamilyStatus] = useState([]);
    const [nationality, setNationality] = useState([]);
    const [experienceLevel, setExperienceLevel] = useState([]);
    const [contractTypes, setContractTypes] = useState([]);
    const [contractType, setContractType] = useState([]);
    const [positionTypes, setPositionTypes] = useState([]);
    const [positionType, setPositionType] = useState([]);
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [cin, setCin] = useState('');
    const [cnss_number, setCnss] = useState('');
    const [sexe, setSexe] = useState('');
    const [birthday, setBirthday] = useState('');
    const [hiring_date, setHiringDate] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [starting_balance, setStartingBalance] = useState('');
    const [experience_level, setSelectedExperienceLevel] = useState('');
    const [family_status, setSelectedFamilyStatus] = useState('');
    const [nationalities, setSelectedNationality] = useState('');
    const [salary, setSalary] = useState('');
    const [msgErr, setMsgErr] = useState('');
    const [msgValidate, setMsgValidate] = useState('');

    const [calendar, setCalendar] = useState('');
    const [calendar2, setCalendar2] = useState('');
    const [calendar3, setCalendar3] = useState('');
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [emailIsValid, setEmailIsValid] = useState('');
    const [formIsValid, setFormIsValid] = useState(false);


    // comportement
    const bearerToken = localStorage.getItem('bearer-token');

    // ** API get Family Status
    async function getFamilyStatus() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/family-status`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                const famStatus = await response.json();
                setFamilyStatus(famStatus);
            } else {
                console.log('Error retrieving :', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    // ** API get Nationality
    async function getNationality() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/nationalities`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                const allNAtionality = await response.json();
                setNationality(allNAtionality);
            } else {
                console.log('Error retrieving :', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    // ** API get Experience Level
    async function getExperienceLevel() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/experience-levels`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                const allExperienceLevel = await response.json();
                setExperienceLevel(allExperienceLevel);
            } else {
                console.log('Error retrieving :', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    // ** API get Positions types
    async function getPositionTypes() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/position-types`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                const allPositionTypes = await response.json();
                setPositionTypes(allPositionTypes);
            } else {
                console.log('Error retrieving :', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    // ** API get contracts types
    async function getContractTypes() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/contract-types`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                const allContractTypes = await response.json();
                setContractTypes(allContractTypes);
            } else {
                console.log('Error retrieving :', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    // Update the email validity state based on the email field's value
    useEffect(() => {
        setEmailIsValid(validateEmail(email));
    }, [email]);

    // ** API create new user
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the email field is empty
        if (email.trim() === '') {
            setEmailIsValid(false);
            return;
        }

        // Check if the email format is valid
        if (!validateEmail(email)) {
            setEmailIsValid(false);
            return;
        }

        const userData = {
            first_name: first_name,
            last_name: last_name,
            email: email,
            experience_level_id: experience_level,
            family_status_id: family_status,
            nationality_id: nationalities,
            cin: cin,
            cnss_number: cnss_number,
            sexe: sexe,
            birthday: birthday,
            hiring_date: hiring_date,
            phone_number: phone_number,
            address: address,
            starting_balance: starting_balance,
            role: role
        };

        const userSalary = {
            salary: salary,
            start_date: hiring_date,
            comment: " "
        }

        const userPosition = {
            position_type_id: positionType,
            start_date: hiring_date,
            // end_date: "",
            comment: " "
        }

        const userContract = {
            contract_type_id: contractType,
            start_date: hiring_date,
            // end_date: "",
            comment: " "
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                },
                body: JSON.stringify(userData)
            });

            if (response.ok) {
                // Reset the form fields
                setFirstName('');
                setLastName('');
                setEmail('');
                setRole('');
                setCin('');
                setCnss('');
                setSexe('');
                setBirthday('');
                setHiringDate('');
                setPhoneNumber('');
                setAddress('');
                setStartingBalance('');
                setSelectedExperienceLevel('');
                setSelectedFamilyStatus('');
                setSelectedNationality('');

                const responseText = await response.text();
                // Find the starting and ending indices of the JSON data
                const startIndex = responseText.indexOf('{');
                const endIndex = responseText.lastIndexOf('}') + 1;
                // Extract the JSON data substring
                const jsonData = responseText.substring(startIndex, endIndex);
                const jsonDataObject = JSON.parse(jsonData);
                // get created user id
                const createdUserId = jsonDataObject.id;


                try {
                    const response2 = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/${createdUserId}/salaries`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${bearerToken}`
                        },
                        body: JSON.stringify(userSalary)
                    });

                    const response3 = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/${createdUserId}/positions`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${bearerToken}`
                        },
                        body: JSON.stringify(userPosition)
                    });

                    const response4 = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/${createdUserId}/contracts`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${bearerToken}`
                        },
                        body: JSON.stringify(userContract)
                    });

                    if (response2.ok && response3.ok && response4.ok) {
                        setSalary('');
                        setContractType('');
                        setPositionType('');
                        console.log('succes');

                    } else {
                        console.log('Error creating user:', response2.status);
                    }

                } catch (error) {
                    console.log('Error:', error);
                }

                setMsgValidate('User created successfully!');
                
            } else {
                setMsgErr('Error creating user! Please review your information and try again.');
                console.log('Error creating user:', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };

    // Email format validation function
    const validateEmail = (email) => {
        // Use a regular expression to validate email format
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };


    // ** date picker birthday
    const refOne = useRef(null);
    const refTwo = useRef(null);

    useEffect(() => {
        // API functions
        getFamilyStatus();
        getNationality();
        getExperienceLevel();
        getPositionTypes();
        getContractTypes();

        // Calendar
        setCalendar(format(subYears(new Date(), 18), 'yyyy-MM-dd'));
        setCalendar2(format(new Date(), 'yyyy-MM-dd'));
        document.addEventListener("keydown", hideOnEscape, true);
        document.addEventListener("click", hideOnClickOutside, true);
    }, []);
    // *** hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key == "Escape") {
            setOpen(false);
            setOpen2(false);
        }
    }
    // *** hide on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false);
        }
        if (refTwo.current && !refTwo.current.contains(e.target)) {
            setOpen2(false);
        }
    }

    // ** errors
    useEffect(() => {
        const identifier = setTimeout(() => {
            setFormIsValid(emailIsValid);
        }, 500);

        return () => {
            clearTimeout(identifier);
        };
    }, [emailIsValid]);

    // affichage
    return (
        <form>
            <div className='add_user_form'>
                <div>
                    <label htmlFor="first_name">First Name</label>
                    <input
                        value={first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                        type="text"
                        name="first_name"
                        id="first_name"
                        placeholder='First Name'
                        required="required"
                    />
                </div>
                <div>
                    <label htmlFor="last_name">Last Name</label>
                    <input
                        value={last_name}
                        onChange={(e) => setLastName(e.target.value)}
                        type="text"
                        name="last_name"
                        id="last_name"
                        placeholder='Last Name'
                        required="required"
                    />
                </div>
                <div>
                    <label htmlFor="role">Role</label>
                    <select
                        name="role"
                        id="role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        required="required"
                    >
                        <option value="0">Role</option>
                        <option value="admin">Admin</option>
                        <option value="user">User</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="email">Email</label>
                    <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        name="email"
                        id="email"
                        placeholder='Email'
                        required="required"
                    />
                    {/* {!formIsValid && email.trim() !== '' &&
                        (<span>Email format is invalid!</span>)
                    } */}

                    {/* Display the error messages */}
                    {/* {!emailIsValid && email.trim() !== '' && (
                        <span>Email format is invalid!</span>
                    )}
                    {!emailIsValid && email.trim() === '' && (
                        <span>Email is required!</span>
                    )} */}
                </div>
                <div>
                    <label htmlFor="cin">CIN</label>
                    <input
                        value={cin}
                        onChange={(e) => setCin(e.target.value)}
                        type="text"
                        name="cin"
                        id="cin"
                        placeholder='CIN'
                        required="required"
                    />
                </div>
                <div>
                    <label htmlFor="cnss">CNSS</label>
                    <input
                        value={cnss_number}
                        onChange={(e) => setCnss(e.target.value)}
                        type="text"
                        name="cnss_number"
                        id="cnss_number"
                        placeholder='CNSS'
                        required="required"
                    />
                </div>
                <div>
                    <label htmlFor="sexe">Sexe</label>
                    <select
                        name="sexe"
                        id="sexe"
                        value={sexe}
                        onChange={(e) => setSexe(e.target.value)}
                        required="required"
                    >
                        <option value="0">Sexe</option>
                        <option value="female">Femme</option>
                        <option value="male">Homme</option>
                    </select>
                </div>

                <div className='birthday_date'>
                    <label htmlFor="birthday">Birthday</label>
                    <input
                        value={calendar}
                        name="birthday"
                        id="birthday"
                        required="required"
                        readOnly
                        onClick={() => setOpen((open) => !open)}
                    />
                    <div ref={refOne}>
                        {open &&
                            <Calendar
                                date={subYears(new Date(), 18)}
                                value={calendar}
                                onChange={(date) => {
                                    setCalendar(format(date, 'yyyy-MM-dd'));
                                    setBirthday(format(date, 'yyyy-MM-dd')); // Update the birthday state
                                }}
                                minDate={parseISO('1950-01-01')}
                                maxDate={subYears(new Date(), 18)}
                            />}
                    </div>
                </div>
                <div className='hiring_date'>
                    <label htmlFor="hiring_date">Hiring Date</label>
                    <input
                        value={calendar2}
                        name="hiring_date"
                        id="hiring_date"
                        required="required"
                        readOnly
                        onClick={() => setOpen2((open2) => !open2)}
                    />
                    <div ref={refTwo}>
                        {open2 &&
                            <Calendar
                                date={subYears(new Date(), 18)}
                                value={calendar2}
                                onChange={(date) => {
                                    setCalendar2(format(date, 'yyyy-MM-dd'));
                                    setHiringDate(format(date, 'yyyy-MM-dd')); // Update the birthday state
                                }}
                                minDate={parseISO('2017-01-01')}
                                maxDate={new Date()}
                            />}
                    </div>
                </div>
                <div>
                    <label htmlFor="phone_number">Phone Number</label>
                    <input
                        value={phone_number}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        placeholder='Phone number'
                        required="required"
                    />
                </div>
                <div>
                    <label htmlFor="address">Address</label>
                    <input
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        type="text"
                        name="address"
                        id="address"
                        placeholder='Adress'
                        required="required"
                    />
                </div>
                <div>
                    <label htmlFor="starting_balance">Starting balance</label>
                    <input
                        value={starting_balance}
                        onChange={(e) => setStartingBalance(e.target.value)}
                        type="number"
                        name="starting_balance"
                        id="starting_balance"
                        placeholder='Starting balance'
                        required="required"
                    />
                </div>
                <div>
                    <label htmlFor="experience_level">Experience Level</label>
                    <select
                        name="experience_level"
                        id="experience_level"
                        required="required"
                        value={experience_level}
                        onChange={(e) => setSelectedExperienceLevel(e.target.value)}
                    >
                        <option value="0">Experience Level</option>
                        {experienceLevel.map(data => (
                            <option key={data.id} value={data.id}>{data.name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="family_status">Family Status</label>
                    <select
                        name="family_status"
                        id="family_status"
                        required="required"
                        value={family_status}
                        onChange={(e) => setSelectedFamilyStatus(e.target.value)}
                    >
                        <option value="0">Family Status</option>
                        {familyStatus.map(data => (
                            <option key={data.id} value={data.id}>{data.name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="nationality">Nationality</label>
                    <select
                        name="nationality"
                        id="nationality"
                        required="required"
                        value={nationalities}
                        onChange={(e) => setSelectedNationality(e.target.value)}
                    >
                        <option value="0">Nationality</option>
                        {nationality.map(data => (
                            <option key={data.id} value={data.id}>{data.name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="position_type">Position</label>
                    <select
                        name="position_type"
                        id="position_type"
                        required="required"
                        value={positionType}
                        onChange={(e) => setPositionType(e.target.value)}
                    >
                        <option value="0">Position</option>
                        {positionTypes.map(data => (
                            <option key={data.id} value={data.id}>{data.name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="contract_type">Contract</label>
                    <select
                        name="contract_type"
                        id="contract_type"
                        required="required"
                        value={contractType}
                        onChange={(e) => setContractType(e.target.value)}
                    >
                        <option value="0">Contract</option>
                        {contractTypes.map(data => (
                            <option key={data.id} value={data.id}>{data.name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="salary">Salary</label>
                    <input
                        value={salary}
                        onChange={(e) => setSalary(e.target.value)}
                        type="text"
                        name="salary"
                        id="salary"
                        placeholder='Salary'
                        required="required"
                    />
                </div>
            </div>
            <button onClick={handleSubmit}>Submit</button>
            {msgErr != "" &&
                <p className='errorMsg center mr-t-10'>{msgErr}</p>
            }
            {msgValidate != "" &&
                <p className='validateMsg center mr-t-10'>{msgValidate}</p>
            }
        </form>
    )
}
