import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { subYears, parseISO } from 'date-fns';
import format from 'date-fns/format';
import axios from 'axios';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "./Adduserform.css";
import "./Edituserform.css";



export default function EditUserForm({ userId }) {
    // state
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [cin, setCin] = useState('');
    const [sexe, setSexe] = useState('');
    const [familyStatus, setFamilyStatus] = useState([]);
    const [experienceLevel, setExperienceLevel] = useState([]);
    const [nationality, setNationality] = useState([]);
    const [user, setUser] = useState({});
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [cnss_number, setCnss] = useState('');
    const [address, setAddress] = useState('');
    const [starting_balance, setStartingBalance] = useState('');
    const [experience_level, setSelectedExperienceLevel] = useState('');
    const [family_status, setSelectedFamilyStatus] = useState('');
    const [nationalities, setSelectedNationality] = useState('');
    const [birthday, setBirthday] = useState('');
    const [hiring_date, setHiringDate] = useState('');
    const [calendar, setCalendar] = useState('');
    const [calendar2, setCalendar2] = useState('');
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [msgErr, setMsgErr] = useState('');
    const [msgValidate, setMsgValidate] = useState('');

    // comportement
    const bearerToken = localStorage.getItem('bearer-token');

    // ** API get Nationality
    async function getNationality() {
        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/nationalities`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                const allNAtionality = await response.json();
                setNationality(allNAtionality);
            } else {
                console.log('Error retrieving :', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    // ** API get Family Status
    async function getFamilyStatus() {
        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/family-status`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                const famStatus = await response.json();
                setFamilyStatus(famStatus);
            } else {
                console.log('Error retrieving :', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    // ** API get Experience Level
    async function getExperienceLevel() {
        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/experience-levels`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            if (response.ok) {
                const allExperienceLevel = await response.json();
                setExperienceLevel(allExperienceLevel);
            } else {
                console.log('Error retrieving :', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    // ** API get User by id
    async function getUsers() {
        try {


            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            });


            if (response.ok) {
                const userData = await response.json();
                setUser(userData);
                setFirstName(userData.first_name);
                setLastName(userData.last_name);
                setRole(userData.role);
                setEmail(userData.email);
                setCin(userData.cin);
                setSexe(userData.sexe);
                setPhoneNumber(userData.phone_number);
                setAddress(userData.address);
                setStartingBalance(userData.starting_balance);
                setSelectedExperienceLevel(userData.experience_level.id);
                setSelectedFamilyStatus(userData.family_status.id);
                setSelectedNationality(userData.nationality.id);
                setHiringDate(userData.hiring_date);
                setBirthday(userData.birthday);
                setCnss(userData.cnss_number);

            } else {
                console.log('Error retrieving :', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleRoleChange = (e) => {
        setRole(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleCinChange = (e) => {
        setCin(e.target.value);
    }

    const handleSexeChange = (e) => {
        setSexe(e.target.value);
    }

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handleStartingBalanceChange = (e) => {
        setStartingBalance(e.target.value);
    };

    const handleExperienceLevelChange = (e) => {
        setSelectedExperienceLevel(e.target.value);
    };

    const handleFamilyStatusChange = (e) => {
        setSelectedFamilyStatus(e.target.value);
    };
    const handleNationalityChange = (e) => {
        setSelectedNationality(e.target.value)
    };

    // ** API edit user
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {


            const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}`, {

                first_name: first_name,
                last_name: last_name,
                role: role,
                cin: cin,
                sexe: sexe,
                cnss_number: cnss_number,
                email: email,
                phone_number: phone_number,
                address: address,
                starting_balance: starting_balance,
                experience_level_id: experience_level,
                family_status_id: family_status,
                nationality_id: nationalities,
                birthday: birthday,
                hiring_date: hiring_date,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                },
            });

            if (response.status >= 200 && response.status < 300) {
                // User successfully updated
                setMsgValidate('User updated successfully!');
                console.log('User updated successfully!');
            } else {
                console.log('Error updating user:', response.status);
                setMsgErr('Error updating user!  Please review the informations and try again.');
                // You might want to handle different error cases here
            }
        } catch (error) {
            console.log('Network error or other exception:', error);
            // Handle network errors or other exceptions here
        }
    };

    // ** date picker birthday
    const refOne = useRef(null);
    const refTwo = useRef(null);

    useEffect(() => {
        // Calendar
        setCalendar(format(subYears(new Date(), 18), 'yyyy-MM-dd'));
        setCalendar2(format(new Date(), 'yyyy-MM-dd'));
        document.addEventListener("keydown", hideOnEscape, true);
        document.addEventListener("click", hideOnClickOutside, true);

        //  API functiosn
        getNationality();
        getFamilyStatus();
        getExperienceLevel();
        getUsers();
    }, []);
    // *** hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key == "Escape") {
            setOpen(false);
            setOpen2(false);
        }
    }
    // *** hide on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false);
        }
        if (refTwo.current && !refTwo.current.contains(e.target)) {
            setOpen2(false);
        }
    }

    // affichage
    return (
        <>
            {/* <h2 className='name'>{first_name} {last_name}</h2> */}
            <form onSubmit={handleSubmit}>
                <div className='add_user_form edit-user'>
                    <div>
                        <label htmlFor="first_name">First Name</label>
                        <input
                            value={first_name}
                            type="text"
                            name="first_name"
                            id="first_name"
                            required="required"
                            placeholder='First Name'
                            onChange={handleFirstNameChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="last_name">Last Name</label>
                        <input
                            value={last_name}
                            type="text"
                            name="last_name"
                            id="last_name"
                            required="required"
                            placeholder='Last Name'
                            onChange={handleLastNameChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="role">Role</label>
                        <select
                            name="role"
                            id="role"
                            required="required"
                            value={role}
                            onChange={handleRoleChange}
                        >
                            <option value="0">Role</option>
                            <option value="admin">Admin</option>
                            <option value="user">User</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="email">Email</label>
                        <input
                            type="text"
                            name="email"
                            id="email"
                            placeholder='Email'
                            required="required"
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="cin">CIN</label>
                        <input
                            type="text"
                            name="cin"
                            id="cin"
                            placeholder='CIN'
                            value={cin}
                            onChange={handleCinChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="cnss">CNSS</label>
                        <input
                            value={cnss_number}
                            onChange={(e) => setCnss(e.target.value)}
                            type="text"
                            name="cnss_number"
                            id="cnss_number"
                            placeholder='CNSS'
                        />
                    </div>
                    <div>
                        <label htmlFor="sexe">Sexe</label>
                        <select
                            name="sexe"
                            required="required"
                            id="sexe"
                            value={sexe}
                            onChange={handleSexeChange}
                        >
                            <option value="0">Sexe</option>
                            <option value="female">Femme</option>
                            <option value="male">Homme</option>
                        </select>
                    </div>
                    <div className='birthday_date'>
                        <label htmlFor="birthday">Birthday</label>
                        <input
                            value={birthday || calendar}
                            name="birthday"
                            id="birthday"
                            required="required"
                            readOnly
                            onClick={() => setOpen((open) => !open)}
                        />
                        <div ref={refOne}>
                            {open &&
                                <Calendar
                                    date={subYears(new Date(), 18)}
                                    value={birthday || calendar}
                                    onChange={(date) => {
                                        setCalendar(format(date, 'yyyy-MM-dd'));
                                        setBirthday(format(date, 'yyyy-MM-dd')); // Update the birthday state
                                    }}
                                    minDate={parseISO('1950-01-01')}
                                    maxDate={subYears(new Date(), 18)}
                                />}
                        </div>
                    </div>
                    <div className='hiring_date'>
                        <label htmlFor="hiring_date">Hiring Date</label>
                        <input
                            value={hiring_date || calendar2}
                            name="hiring_date"
                            id="hiring_date"
                            required="required"
                            readOnly
                            onClick={() => setOpen2((open2) => !open2)}
                        />
                        <div ref={refTwo}>
                            {open2 &&
                                <Calendar
                                    date={subYears(new Date(), 18)}
                                    value={hiring_date || calendar2}
                                    onChange={(date) => {
                                        setCalendar2(format(date, 'yyyy-MM-dd'));
                                        setHiringDate(format(date, 'yyyy-MM-dd')); // Update the birthday state
                                    }}
                                    minDate={parseISO('2017-01-01')}
                                    maxDate={new Date()}
                                />}
                        </div>
                    </div>
                    <div>
                        <label htmlFor="phone_number">Phone Number</label>
                        <input
                            type="text"
                            name="phone_number"
                            id="phone_number"
                            placeholder='Phone number'
                            required="required"
                            value={phone_number}
                            onChange={handlePhoneNumberChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="address">Address</label>
                        <input
                            type="text"
                            name="address"
                            id="address"
                            placeholder='Adress'
                            required="required"
                            value={address}
                            onChange={handleAddressChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="starting_balance">Starting balance</label>
                        <input
                            type="number"
                            name="starting_balance"
                            id="starting_balance"
                            placeholder='Starting balance'
                            required="required"
                            value={starting_balance}
                            onChange={handleStartingBalanceChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="experience_level">Experience Level</label>
                        <select
                            name="experience_level"
                            id="experience_level"
                            required="required"
                            value={experience_level}
                            onChange={handleExperienceLevelChange}
                        >
                            <option value="0">Experience Level</option>
                            {experienceLevel.map(data => (
                                <option key={data.id} value={data.id}>{data.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="family_status">Family Status</label>
                        <select
                            name="family_status"
                            id="family_status"
                            required="required"
                            value={family_status}
                            onChange={handleFamilyStatusChange}
                        >
                            <option value="0">Family Status</option>
                            {familyStatus.map(data => (
                                <option key={data.id} value={data.id}>{data.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="nationality">Nationality</label>
                        <select
                            name="nationality"
                            id="nationality"
                            required="required"
                            value={nationalities}
                            onChange={handleNationalityChange}
                        >
                            <option value="0">Nationality</option>
                            {nationality.map(data => (
                                <option key={data.id} value={data.id}>{data.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <button type="submit">Submit</button>
                {msgErr != "" &&
                    <p className='errorMsg center mr-t-10'>{msgErr}</p>
                }
                {msgValidate != "" &&
                    <p className='validateMsg center mr-t-10'>{msgValidate}</p>
                }
            </form>
        </>
    )
}
