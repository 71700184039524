import React from 'react'
import { Link, useLocation } from "react-router-dom";

export default function SubMenu() {
    // comportement
    const location = useLocation();

    // affichage
    return (
        <div className='submenu'>
            <Link to="/attachment-types" className={`${location.pathname === '/attachment-types' ? 'currentSub' : ''}`}>Attachment types</Link>
            <Link to="/contract-types" className={`${location.pathname === '/contract-types' ? 'currentSub' : ''}`}>Contract types</Link>
            <Link to="/experience-levels" className={`${location.pathname === '/experience-levels' ? 'currentSub' : ''}`}>Experience levels</Link>
            <Link to="/family-status" className={`${location.pathname === '/family-status' ? 'currentSub' : ''}`}>Family status</Link>
            <Link to="/nationalities" className={`${location.pathname === '/nationalities' ? 'currentSub' : ''}`}>Nationalities</Link>
            <Link to="/position-types" className={`${location.pathname === '/position-types' ? 'currentSub' : ''}`}>Position types</Link>
            <Link to="/vacation-types" className={`${location.pathname === '/vacation-types' ? 'currentSub' : ''}`}>Vacation types</Link>
        </div>
    )
}
