import React from 'react';
import ArrowTop from '../../img/arrowTop.svg';
import './Circlecharts.css';

export default function CircleCharts({ data }) {
    // comportements
    const Data1 = data[0];
    const Data2 = data[1];
    const Data3 = data[2];

    const chartData1 = (Data1.pourcentage * 360) / 100;
    const chartData2 = (Data2.pourcentage * 360) / 100;
    const chartData3 = (Data3.pourcentage * 360) / 100;

    const first = chartData1;
    const second = chartData2 + chartData1;
    // const third = 

    // affichage
    return (
        <div className='CircleChart'>
            <div className='top_chart'>
                <h3>Chart title</h3>
                <p>
                    <img src={ArrowTop} alt="arrowTop" />
                    1.10% Since yesterday
                </p>
            </div>
            <div
                className="circle"
                style={{
                    backgroundImage: `conic-gradient(#FF7777 0 ${first - 1}deg, #FFFFFF ${first - 1}deg ${first}deg, #1C5DAF ${first}deg ${second -1}deg, #FFFFFF ${second -1}deg ${second}deg,  #2BEBC8 ${second}deg 359deg, #FFFFFF 359deg 360deg)`
                }}>
                <div
                    className="inner-circle"></div>
            </div>
        </div>
    )
}
