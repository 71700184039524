import React from 'react'

export default function CloseButton({ onClick }) {
    return (
        <div className="closePop" onClick={onClick}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_1136_3274)">
                    <path
                        d="M1.07339 1.10052C1.66712 0.49272 2.64116 0.481313 3.24896 1.07504L18.6562 16.1257C19.264 16.7194 19.2754 17.6934 18.6817 18.3012V18.3012C18.088 18.909 17.114 18.9204 16.5062 18.3267L1.09887 3.27609C0.491071 2.68236 0.479664 1.70832 1.07339 1.10052V1.10052Z"
                        fill="#4B5FCD"
                    />
                    <path
                        d="M1.12585 18.5311C0.517006 17.9385 0.503881 16.9644 1.09654 16.3556L16.12 0.921786C16.7126 0.31294 17.6866 0.299814 18.2955 0.892471V0.892471C18.9043 1.48513 18.9174 2.45914 18.3248 3.06799L3.30137 18.5018C2.70871 19.1106 1.7347 19.1238 1.12585 18.5311V18.5311Z"
                        fill="#4B5FCD"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1136_3274">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}

